@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d; /* Dark gray background */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #7f40ff, #ff4eb6); /* Gradient from purple to pink */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #9b60ff, #ff6fcf); /* Lighter gradient on hover */
}

::-webkit-scrollbar-thumb:active {
  background: linear-gradient(180deg, #5a24cc, #d03594); /* Darker gradient on active */
}

.vertical-timeline-element-date {
  margin: 0 1rem !important;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element-date {
    margin: 0 1rem !important;
  }
}